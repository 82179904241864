<template>
  <div>
    <template v-if="$route.path !== '/'">
      <router-view></router-view>
    </template>
    <div class="no-date" v-else>
      <img src="../../assets/noDate.png" class="" alt="" />
      <p class="no-data-text">功能暂未开放</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style lang='scss' scoped>
.home-page {
  height: 100%;
  position: relative;
}
.no-date {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .no-data-text {
    text-align: center;
    margin-top: 30px;
    color: #8492a6;
  }
}
</style>
